export const AUTH_REQUEST = 'auth_request'
export const SOCIAL_AUTH_REQUEST = 'social_auth_request'
export const AUTH_LOGOUT = 'auth_logout'
export const AUTH_SUCCESS = 'auth_success'
export const AUTH_ERROR = 'auth_error'

export const CLIENT_GRANT_TYPE = 'client_credentials'
export const CLIENT_TOKEN_REQUEST = 'client_token_request'
export const CLIENT_TOKEN_REMOVE = 'client_token_remove'
export const CLIENT_TOKEN_SUCCESS = 'client_token_success'
export const CLIENT_TOKEN_ERROR = 'client_token_error'

export const GET_ANNOUNCEMENT = 'get_announcement'
export const SET_ANNOUNCEMENT = 'set_announcement'

export const GET_PRODUCTS = 'get_products'
export const SET_PRODUCTS = 'set_products'
export const SET_PRODUCTS_META = 'set_products_meta'
export const FILTER_PRODUCTS = 'filter_products'
export const GET_USER_TRANSACTION_PRODUCTS = 'get_user_transaction_products'
export const CLEAR_USER_TRANSACTION_PRODUCTS = 'clear_user_transaction_products'
export const GET_USER_TRANSACTION_PRODUCTS_SUCCESS = 'get_user_transaction_products_success'
export const DOWNLOAD_PRODUCT_CONFIRMATION_REQUEST = 'download_product_confirmation_request'
export const DOWNLOAD_BALANCE_CONFIRMATION_REQUEST = 'download_balance_confirmation_request'
export const DOWNLOAD_PRODUCT_CONFIRMATION_SUCCESS = 'download_product_confirmation_success'
export const DOWNLOAD_BALANCE_CONFIRMATION_SUCCESS = 'download_balance_confirmation_success'
export const DOWNLOAD_PRODUCT_CONFIRMATION_ERROR = 'download_product_confirmation_error'
export const DOWNLOAD_BALANCE_CONFIRMATION_ERROR = 'download_balance_confirmation_error'
export const CANCEL_PRODUCT_REQUEST = 'cancel_product_request'
export const CANCEL_PRODUCT_REQUEST_SUCCESS = 'cancel_product_request_succes'
export const CANCEL_PRODUCT_REQUEST_ERROR = 'cancel_product_request_error'
export const SET_PRODUCT_ENTRY = 'set_product_entry'
export const SET_PRODUCT_FOR_ENTRY = 'set_product_for_entry'
export const SET_PRODUCT_PARTICIPANT = 'set_product_participant'
export const GET_USER_FELLOWS_REQUEST = 'get_user_fellows_request'
export const GET_USER_ENTRIES_FOR_EVENT_REQUEST = 'get_user_entries_for_event_request'
export const GET_USER_ENTRIES_FOR_EVENT_IN_CART_REQUEST = 'get_user_entries_for_event_in_cart_request'
export const GET_USER_ENTRIES_FOR_EVENT_SUCCESS = 'get_user_entries_for_event_success'

export const GET_COUPONS = 'get_coupons'
export const SET_COUPONS = 'set_coupons'
export const VALIDATE_COUPON = 'validate_coupon'
export const VALIDATE_COUPON_SUCCESS = 'validate_coupon_success'
export const VALIDATE_COUPON_ERROR = 'validate_coupon_error'
export const UPDATE_CART_WITH_DISCOUNT = 'update_cart_with_discount'
export const ADD_COUPON = 'add_coupon'
export const REMOVE_COUPON = 'remove_coupon'
export const REMOVE_COUPONS = 'remove_coupons'

export const ADD_ENTRY_TO_CART = 'add_entry_to_cart'
export const REMOVE_ENTRY_FROM_CART_REQUEST = 'remove_entry_from_cart_request'
export const REMOVE_ENTRY_FROM_CART = 'remove_entry_from_cart'

export const UPDATE_CART_TEAM_ENTRY = 'update_cart_team_entry'

export const ADD_TEAM_MEMBER_ENTRY = 'add_member_entry'
export const REMOVE_TEAM_MEMBER_ENTRY = 'remove_team_member_entry'
export const SUBMIT_TEAM_ENTRY = 'submit_team_entry'
export const CLEAR_TEAM_ENTRY = 'clear_team_entry'
export const SET_TEAM_LEADER = 'set_team_leader'
export const CLEAR_TEAM_LEADER = 'clear_team_leader'

export const SET_NEW_TEAM_MEMBER = 'set_new_team_member'
export const SET_EDIT_TEAM_MEMBER = 'set_edit_team_member'
export const CLEAR_EDIT_TEAM_MEMBER = 'clear_edit_team_member'
export const SET_EDIT_TEAM_MEMBER_STATUS = 'set_edit_team_member_status'
export const SET_EDIT_TEAM_MEMBERS = 'set_edit_team_members'
export const CLEAR_EDIT_TEAM_MEMBERS = 'clear_edit_team_members'

export const SET_ENTRY_PARTICIPANT = 'set_entry_participant'
export const CLEAR_ENTRY_PARTICIPANT = 'clear_entry_participant'

export const REMOVE_TEAM_ENTRY_FROM_CART_REQUEST = 'remove_team_entry_from_cart_request'
export const REMOVE_TEAM_ENTRY_FROM_CART = 'remove_team_entry_from_cart'

export const ADD_PRODUCT_TO_CART = 'add_product_to_cart'
export const REMOVE_PRODUCT_FROM_CART = 'remove_product_from_cart'

export const REGISTRATION_REQUEST = 'registration_request'
export const REGISTRATION_SET_USER = 'registration_set_user'
export const REGISTRATION_SUCCESS = 'registration_success'
export const REGISTRATION_ERROR = 'registration_error'
export const REGISTRATION_SET_USER_ALREADY_REGISTERED = 'registration_set_user_already_registered'
export const CLEAR_REGISTRATION_STATUS = 'clear_registration_status'

export const REGISTRATION_CLEAR_USER = 'registration_clear_user'

export const REGISTRATION_ACTIVATION_REQUEST = 'registration_activation_request'
export const REGISTRATION_ACTIVATION_SUCCESS = 'registration_activation_success'
export const REGISTRATION_ACTIVATION_ERROR = 'registration_activation_error'

export const REGISTRATION_OLD_ACTIVATION_PASSWORD_REQUEST = 'registration_old_activation_password_request'

export const REGISTRATION_OLD_ACTIVATION_REQUEST = 'registration_old_activation_request'
export const REGISTRATION_OLD_ACTIVATION_SUCCESS = 'registration_old_activation_success'
export const REGISTRATION_OLD_ACTIVATION_ERROR = 'registration_old_activation_error'

export const EMAIL_CHANGE_ACTIVATION_REQUEST = 'email_change_activation_request'
export const EMAIL_CHANGE_ACTIVATION_SUCCESS = 'email_change_activation_success'
export const EMAIL_CHANGE_ACTIVATION_ERROR = 'email_change_activation_error'

export const EMAIL_CHANGE_DURING_REGISTRATION_REQUEST = 'email_change_during_registration_request'
export const EMAIL_CHANGE_DURING_REGISTRATION_SUCCESS = 'email_change_during_registration_success'
export const EMAIL_CHANGE_DURING_REGISTRATION_ERROR = 'email_change_during_registration_error'

export const PASS_RESET_REQUEST = 'pass_reset_request'
export const PASS_RESET_SUCCESS = 'pass_reset_success'
export const PASS_RESET_ERROR = 'pass_reset_error'
export const PASS_RESET_CLEAR = 'pass_reset_clear'

export const PASS_RESET_NEW_REQUEST = 'pass_reset_new_request'
export const PASS_RESET_NEW_SUCCESS = 'pass_reset_new_success'
export const PASS_RESET_NEW_ERROR = 'pass_reset_new_error'
export const PASS_RESET_NEW_TOKEN_ERROR = 'pass_reset_new_token_error'

export const GET_USER_REQUEST = 'get_user_request'
export const GET_USER_SUCCESS = 'get_user_success'
export const GET_USER_ERROR = 'get_user_error'

export const SET_USER_ID = 'set_user_id'

export const UPDATE_USER_REQUEST = 'update_user_request'
export const UPDATE_USER_SUCCESS = 'update_user_success'
export const UPDATE_USER_ERROR = 'update_user_error'

export const PATCH_USER_REQUEST = 'patch_user_request'
export const PATCH_USER_SUCCESS = 'patch_user_success'
export const PATCH_USER_ERROR = 'patch_user_error'

export const USER_CHANGE_PASSWORD = 'user_change_password'

export const UPLOAD_USER_FILE_REQUEST = 'upload_user_file_request'
export const UPLOAD_USER_FILE_SUCCESS = 'upload_user_file_success'
export const UPLOAD_USER_FILE_ERROR = 'upload_user_file_error'

export const SET_COLLECTIONS = 'set_collections'
export const SET_COLLECTIONS_OPTIONS = 'set_collections_options'
export const GET_COLLECTIONS_OPTIONS = 'get_collections_options'
export const CLEAR_COLLECTIONS = 'clear_collections'

export const EDUCATIONS_COLLECTION = 'educations'
export const PROFESSIONS_COLLECTION = 'professions'
export const OCCUPATIONS_COLLECTION = 'occupations'
export const WATCHES_COLLECTION = 'watches'
export const SHOES_COLLECTION = 'shoes'
export const COUNTRIES_COLLECTION = 'countries'
export const ZIPCODES_COLLECTION = 'zipcodes'
export const SPORTCLUBS_COLLECTION = 'clubs'
export const NATIONALITIES_COLLECTION = 'nationalities'

export const GET_USER_ADDRESSES_REQUEST = 'get_user_addresses_request'
export const GET_USER_ADDRESSES_SUCCESS = 'get_user_addresses_success'
export const GET_USER_ADDRESSES_ERROR = 'get_user_addresses_error'

export const CREATE_USER_ADDRESS_REQUEST = 'create_user_address_request'
export const CREATE_USER_ADDRESS_SUCCESS = 'create_user_address_success'
export const CREATE_USER_ADDRESS_ERROR = 'create_user_address_error'

export const GET_USER_ADDRESS_BY_ID_REQUEST = 'get_user_address_by_id_request'
export const GET_USER_ADDRESS_BY_ID_SUCCESS = 'get_user_address_by_id_success'
export const GET_USER_ADDRESS_BY_ID_ERROR = 'get_user_address_by_id_error'

export const SET_USER_ADDRESS_REQUEST = 'set_user_address_request'
export const SET_USER_ADDRESS_SUCCESS = 'set_user_address_success'
export const SET_USER_ADDRESS_ERROR = 'set_user_address_error'

export const UPDATE_USER_ADDRESS_REQUEST = 'update_user_address_request'
export const UPDATE_USER_ADDRESS_SUCCESS = 'update_user_address_success'
export const UPDATE_USER_ADDRESS_ERROR = 'update_user_address_error'

export const DELETE_USER_ADDRESS_REQUEST = 'delete_user_address_request'
export const DELETE_USER_ADDRESS_SUCCESS = 'delete_user_address_success'
export const DELETE_USER_ADDRESS_ERROR = 'delete_user_address_error'

export const TRANSACTION_NEW_REQUEST = 'transaction_new_request'
export const TRANSACTION_NEW_REQUEST_SUCCESS = 'transaction_new_request_success'
export const TRANSACTION_NEW_REQUEST_ERROR = 'transaction_new_request_error'

export const HANDOVERS_REQUEST = 'handovers_request'
export const HANDOVERS_REQUEST_SUCCESS = 'handovers_request_success'
export const HANDOVERS_REQUEST_ERROR = 'handovers_request_error'

export const SET_HANDOVER_ID = 'set_handover_id'

export const COUPON_VALIDATION_REQUEST = 'coupon_validation_request'
export const COUPON_VALIDATION_REQUEST_SUCCESS = 'coupon_validation_request_success'
export const COUPON_VALIDATION_REQUEST_ERROR = 'coupon_validation_request_error'

export const EMPTY_CART = 'empty_cart'

export const GET_USER_ENTRIES_REQUEST = 'get_user_entries_request'
export const GET_USER_ENTRIES_SUCCESS = 'get_user_entries_success'
export const GET_USER_ENTRIES_ERROR = 'get_user_entries_error'
export const CLEAR_USER_ENTRIES = 'clear_user_entries'

export const GET_USER_FELLOW_RUNNERS_REQUEST = 'get_user_fellow_runners_request'
export const GET_USER_FELLOW_RUNNERS_SUCCESS = 'get_user_fellow_runners_success'
export const GET_USER_FELLOW_RUNNERS_ERROR = 'get_user_fellow_runners_error'

export const SET_REGISTRATION_CODE = 'set_registration_code'
export const GET_RACE_TIMETABLE_REQUEST = 'get_race_timetable_request'
export const GET_RACE_TIMETABLE_SUCCESS = 'get_race_timetable_success'
export const GET_RACE_TIMETABLE_ERROR = 'get_race_timetable_error'
export const ADD_TIMETABLE_CLASS = 'add_timetable_class'
export const REMOVE_TIMETABLE_CLASS = 'remove_timetable_class'
export const CLEAR_SELECTED_CLASSES = 'clear_selected_classes'
export const USER_CLASS_REGISTRATION = 'user_class_registration'
export const USER_CLASS_REGISTRATION_SUCCESS = 'user_class_registration_success'

export const SHOW_NAVIGATION = 'show_navigation'
export const HIDE_NAVIGATION = 'hide_navigation'
export const TOGGLE_NAVIGATION = 'toggle_navigation'

export const DOWNLOAD_ENTRY_CONFIRMATION_REQUEST = 'download_entry_confirmation_request'
export const DOWNLOAD_ENTRY_CONFIRMATION_SUCCESS = 'download_entry_confirmation_success'
export const DOWNLOAD_ENTRY_CONFIRMATION_ERROR = 'download_entry_confirmation_error'

export const WITHDRAW_ENTRY_REQUEST = 'withdraw_entry_request'
export const WITHDRAW_ENTRY_SUCCESS = 'withdraw_entry_success'
export const WITHDRAW_ENTRY_ERROR = 'withdraw_entry_error'

export const UPLOAD_ENTRY_NET_TIME = 'upload_entry_net_time'
export const UPLOAD_ENTRY_NET_TIME_SUCCESS = 'upload_entry_net_time_success'
export const UPLOAD_ENTRY_NET_TIME_ERROR = 'upload_entry_net_time_error'

export const UPLOAD_ENTRY_DISTANCE = 'upload_entry_distance'
export const UPLOAD_ENTRY_DISTANCE_SUCCESS = 'upload_entry_distance_success'
export const UPLOAD_ENTRY_DISTANCE_ERROR = 'upload_entry_distance_error'

export const GET_ENTRY_DISTANCES_REQUEST = 'get_entry_distances_request'
export const GET_ENTRY_DISTANCES_SUCCESS = 'get_entry_distances_success'
export const GET_ENTRY_DISTANCES_ERROR = 'get_entry_distances_error'
export const DELETE_ENTRY_DISTANCE_REQUEST = 'delete_entry_distance_request'

export const ADD_ENTRY_TO_CART_ACTION = 'add_entry_to_cart'
export const ADD_TEAM_ENTRY_TO_CART = 'add_team_entry_to_cart'
export const REMOVE_ALL_ENTRIES_FROM_CART = 'remove_all_entries_from_cart'

export const ADD_MISSING_INFO_TO_USER_ENTRY = 'add_missing_info_to_user_entry'
export const ADD_MISSING_INFO_TO_USER_ENTRY_SUCCESS = 'add_missing_info_to_user_entry_success'
export const ADD_MISSING_INFO_TO_USER_ENTRY_ERROR = 'add_missing_info_to_user_entry_error'

export const SET_CART_TIMEOUT = 'set_cart_timeout'
export const SET_ENTRY_COUNTDOWN_TIMER = 'set_entry_countdown_timer'
export const UPDATE_ENTRY_COUNTDOWN_TIMER = 'update_entry_countdown_timer'
export const RESET_CART_TIMEOUT = 'reset_cart_timeout'
export const CHECK_USER = 'check_user'

export const SET_CART_ITEM_DELIVERY = 'set_cart_item_delivery'
export const SET_CART_SHIPPING_COST = 'set_cart_shipping_cost'
export const CLEAR_SHIPPING = 'clear_shipping'

export const GET_ENTRY = 'get_entry'
export const SET_SELECTED_ENTRY = 'set_selected_entry'
export const SUBMIT_INDIVIDUAL_ENTRY = 'submit_individual_entry'

export const SET_USER_CREDIT = 'set_user_credit'

export const SELECT_PAYMENT_METHOD = 'select_payment_method'

export const GET_MARKETING_CONSENT = 'get_marketing_consent'
export const FETCH_MARKETING_CONSENT = 'fetch_marketing_consent'
export const SET_MARKETING_CONSENT = 'set_marketing_consent'
export const REGISTRATION_SET_USER_profile = 'registration_set_user_profile'

export const ADD_TO_BARION_CART = 'add_to_barion_cart'
export const ADD_TO_BARION_CART_BEFORE_PAYMENT = 'add_to_barion_before_payment'
export const BARION_CART_AFTER_PAYMENT = 'barion_cart_after_payment'

export const SET_CART_CHECK = 'set_cart_check'
export const SET_VALID_ENTRY = 'set_valid_entry'

export const SET_VALIDATED_RELATED_FIELD = 'set_validated_related_field'
export const UNSET_VALIDATED_RELATED_FIELD = 'unset_validated_related_field'
export const SET_SELECTED_FEE = 'set_selected_fee'
export const UNSET_SELECTED_FEE = 'unset_selected_fee'
export const SET_VALIDATED_FIELD_VALUE = 'set_validated_field_value'
export const SET_VALIDATED_FIELD = 'set_validated_field'
export const UNSET_VALIDATED_FIELD = 'unset_validated_field'
export const ADD_VALIDATED_FIELD = 'add_validated_field'
export const GET_USER_NEWSLETTER_SERIES_REQUEST = 'get_user_newsletter_series_request'
export const GET_USER_NEWSLETTER_REQUEST = 'get_user_newsletter_request'
export const GET_NEWSLETTER_REQUEST = 'get_newsletter_request'
export const GET_USER_NEWSLETTER_BY_ID_REQUEST = 'get_user_newsletter_by_id_request'
export const GET_USER_NEWSLETTER_SERIES_BY_ID_REQUEST = 'get_user_newsletter_series_by_id_request'
export const GET_USER_NEWSLETTER_SUCCESS = 'get_user_newsletter_success'
export const GET_USER_NEWSLETTER_ERROR = 'get_user_newsletter_error'
export const CLEAR_USER_NEWSLETTER = 'clear_user_newsletter'
export const SET_USER_SUBSCRIPTION = 'set_user_subscription'
export const SET_UNSUBSCRIPTION = 'set_unsubscription'
export const SET_SUBSCRIPTION = 'set_subscription'
export const GET_NEWSLETTER_SUBSCRIPTION_SUCCESS = 'get_newsletter_subscription_success'
export const GET_NEWSLETTER_SUBSCRIPTION_ERROR = 'get_newsletter_subscription_error'
export const GET_NEWSLETTER_UNSUBSCRIPTION_SUCCESS = 'get_newsletter_subscription_success'
export const GET_NEWSLETTER_UNSUBSCRIPTION_ERROR = 'get_newsletter_subscription_error'
export const GET_USER_NEWSLETTER_SERIES_FILTER_REQUEST = 'get_user_newsletter_series_filter_request'
