export default [
  {
    path: '/products',
    name: 'products',
    component: () => import('./pages/Products.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/accommodations',
    name: 'accommodations',
    component: () => import('./pages/Bookings.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/guest-entries',
    name: 'guestEntries',
    component: () => import('./pages/GuestEntries.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/charities',
    name: 'charities',
    component: () => import('./pages/Charities.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/giftcards',
    name: 'giftcards',
    component: () => import('./pages/GiftCards.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/giftcard-redeem',
    name: 'giftcardRedeem',
    component: () => import('./pages/GiftCardRedeem.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/gifts/:giftId',
    name: 'giftcard',
    component: () => import('./pages/GiftCard.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/charity-products/:charityId',
    name: 'charity',
    component: () => import('./pages/Charity.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/products/:productId',
    name: 'product',
    component: () => import('./pages/Product.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/accommodations/:productId',
    name: 'accommodation',
    component: () => import('./pages/Booking.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/guest-entry/:productId',
    name: 'guestEntry',
    component: () => import('./pages/GuestEntry.vue'),
    meta: {
      authenticatedOnly: true
    }
  },
  {
    path: '/users/products',
    name: 'userProducts',
    component: () => import('./pages/UserProducts.vue'),
    meta: {
      authenticatedOnly: true
    }
  }
]
